import { createApp, computed, inject, provide } from 'vue'
import { createPinia } from 'pinia'
// import process from 'process'
import App from './App.vue'
import router from './router'
import '@/styles/main.css'
// 倒入路由鉴权
import '@/router/permission'
import persistedstate from 'pinia-plugin-persistedstate'
import { userInfoStore } from './stores/user'
// import LoadingDirective from './directives/vLoading'
import ROLE from './config/role'

// @ts-ignore
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query'
// import { isStoreManager } from './config/constants'
// import { ConfigProvider } from 'ant-design-vue'
// import zhCN from 'ant-design-vue/es/locale/zh_CN'
const app = createApp(App)
// if (process.env.NODE_ENV === 'development') {
//     app.config.devtools = true;
//   }
const pinia = createPinia()
pinia.use(persistedstate)
app.use(pinia)
// app.directive('loading', LoadingDirective)
// import { domTitle, setDocumentTitle } from '@/utils/domUtil'
// import { i18nRender } from '@/locales'
// import { API_bindAuthToken } from '@/api/userList.js'
// import { getParameterByName } from '@/utils/util.js'
const userInfo = userInfoStore()
// const queryClient = new QueryClient()
// app.provide('queryClient', queryClient)

// console.log('queryClient>>>>', inject('queryClient'))
const baseMixin = {
  computed: {
    isStoreManager() {
      return userInfo.info && userInfo.info?.roleId === ROLE.STORE_ADMIN_ROLE_ID
    }
  }
}
// app.provide(
//   isStoreManager,
//   userInfo.info && userInfo.info?.roleId === ROLE.STORE_ADMIN_ROLE_ID
// )
// app.use(ConfigProvider, { locale: zhCN })
app.use(router)
app.use(VueQueryPlugin, {
  enableDevtoolsV6Plugin: true
})
app.mixin(baseMixin)
app.mount('#app')
