import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
  RouterView
} from 'vue-router'

import LayoutComponent from '@/layouts/BasicLayout.vue'
import Login from '@/views/login/main.vue'
import LoginINdex from '@/views/login/index.vue'
import Register from '@/views/login/register.vue'
import FindPassword from '@/views/login/components/find.vue'
import FailedReview from '@/views/login/failedReview.vue'

// 前端路由表 (基于动态)

// 公开路由
export const publicRoutes: RouteRecordRaw[] = [
  {
    path: '/user',
    name: 'user',
    // redirect: '/user/login',
    component: () => import('@/layouts/UserLayout.vue'),
    children: [
      {
        path: '/user/login',
        name: 'login',
        component: Login
      },
      {
        path: '/user/find-password',
        name: 'findPassword',
        component: FindPassword
      },
      {
        path: '/user/register',
        name: 'register',
        component: Register
      },
      {
        path: '/user/failed',
        name: 'failed',
        component: FailedReview
      }
    ]
  },
  {
    path: '/404',
    name: 'err404',
    meta: {
      title: '404',
      hidden: false
    },
    component: () => import('@/views/errorPage/err404.vue')
  },
  {
    path: '/',
    meta: {
      title: ''
    },
    redirect: '/store',
    component: () => import('@/layouts/BasicLayout.vue'),
    children: [
      {
        path: '/admin-store',
        name: 'AdminStore',
        meta: {
          title: '门店管理'
        },
        component: () => import('@/views/store/enterpriseAdministrator.vue')
      }
      // {
      //   path: '/video',
      //   name: 'permissions',
      //   meta: {
      //     title: '视频',
      //     roles: ['admin']
      //   },
      //   component: RouterView,
      //   children: [
      //     {
      //       path: '/video/list',
      //       name: 'videoList',
      //       meta: {
      //         title: '视频中心'
      //       },
      //       component: () => import('@/views/video/index.vue')
      //     },
      //     {
      //       path: '/video/template',
      //       name: 'videoTemplate',
      //       meta: {
      //         title: '视频模板'
      //       },
      //       component: () => import('@/views/video/template.vue')
      //     }
      //   ]
      // },
    ]
  }

  // {
  //   path: '/login',
  //   name: 'login',
  //   component: LoginINdex
  // },
]

// 权限路由
export const asyncRoutes: RouteRecordRaw[] = [
  // {
  //   path: '/',
  //   redirect: '/store',
  //   meta: {
  //     title: ''
  //   },
  //   component: LayoutComponent,
  //   children: []
  // },
  {
    path: '/',
    meta: {
      title: ''
    },
    redirect: '/store',
    component: LayoutComponent,
    children: [
      {
        path: '/store',
        name: 'store',
        meta: {
          title: '门店'
        },
        component: () => import('@/views/store/index.vue'),
        children: []
      },
      {
        path: '/store/new-store',
        name: 'newStore',
        meta: {
          title: '新建门店',
          hidden: true,
          hideChildrenInMenu: true
        },
        component: () => import('@/views/store/components/newStore.vue'),
        children: [
          {
            path: '/store/new-store/store-info',
            name: 'storeInfo',
            meta: {
              title: '门店信息',
              hidden: true
            },
            component: () => import('@/views/store/components/storeInfo.vue')
          },
          {
            path: '/store/new-store/associate-cabinet',
            name: 'associateCabinet',
            meta: {
              title: '关联数智柜',
              hidden: true
            },
            component: () => import('@/views/store/components/storeInfo.vue')
          },
          {
            path: '/store/new-store/associate-staff',
            name: 'associateStaff',
            meta: {
              title: '关联柜员',
              hidden: true
            },
            component: () => import('@/views/store/components/storeInfo.vue')
          },
          {
            path: '/store/new-store/digital-man',
            name: 'digitalMan',
            meta: {
              title: '数字人',
              hidden: true
            },
            component: () => import('@/views/store/components/storeInfo.vue')
          }
        ]
      },
      {
        path: '/cabinet',
        name: 'smartVend',
        meta: {
          title: '数智柜',
          hideChildrenInMenu: true
        },
        component: () => import('@/views/smartVend/index.vue'),
        children: []
      },
      {
        path: '/goods',
        name: 'goods',
        meta: {
          title: '商品',
          hideChildrenInMenu: true
        },
        component: () => import('@/views/home/index.vue'),
        children: []
      },
      {
        path: '/scene',
        name: 'scene',
        meta: {
          title: '场景',
          hideChildrenInMenu: true
        },
        component: () => import('@/views/scene/index.vue'),
        children: []
      },
      // {
      //   path: '/video',
      //   name: 'permissions',
      //   meta: {
      //     title: '视频',
      //     roles: ['admin']
      //   },
      //   component: RouterView,
      //   children: [
      //     {
      //       path: '/video/list',
      //       name: 'videoList',
      //       meta: {
      //         title: '视频中心'
      //       },
      //       component: () => import('@/views/video/index.vue')
      //     },
      //     {
      //       path: '/video/template',
      //       name: 'videoTemplate',
      //       meta: {
      //         title: '视频模板'
      //       },
      //       component: () => import('@/views/video/template.vue')
      //     }
      //   ]
      // },
      {
        path: '/asset',
        name: 'asset ',
        meta: {
          title: '资产',
          hideChildrenInMenu: true
        },
        component: () => import('@/views/asset/index.vue'),
        children: [
          // {
          //   path: '/asset/list',
          //   name: 'assetList',
          //   meta: {},
          //   component: () => import('@/views/table/index.vue')
          // }
        ]
      },
      {
        path: '/staff',
        name: 'staff ',
        meta: {
          title: '员工',
          hideChildrenInMenu: true
        },
        component: () => import('@/views/staff/index.vue'),
        children: [
          // {
          //   path: '/staff/list',
          //   name: 'staffList',
          //   meta: {
          //     title: '员工列表'
          //   },
          //   component: () => import('@/views/table/index.vue')
          // }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: publicRoutes
})

export default router
