const BASE_ID = '10032024111211'
const SUPER_ADMIN_ROLE_ID = [BASE_ID, '495586628561492312'].join('')
const ADMIN_ROLE_ID = [BASE_ID, '500619093605840966'].join('')
const BUSINESS_ADMIN_ROLE_ID = [BASE_ID, '500640097204238259'].join('')
const STORE_ADMIN_ROLE_ID = [BASE_ID, , '500664483593762337'].join('')
const STAFF_ROLE_ID = [BASE_ID, '500640097204238259'].join('')
export const roleMap = {
  [SUPER_ADMIN_ROLE_ID]: '超级管理员',
  [ADMIN_ROLE_ID]: '管理员',
  [BUSINESS_ADMIN_ROLE_ID]: '企业管理员',
  [STORE_ADMIN_ROLE_ID]: '门店管理员',
  [STAFF_ROLE_ID]: '员工'
}
export default {
  SUPER_ADMIN_ROLE_ID,
  ADMIN_ROLE_ID,
  BUSINESS_ADMIN_ROLE_ID,
  STORE_ADMIN_ROLE_ID,
  STAFF_ROLE_ID,
  roleMap
}
